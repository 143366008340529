// Component that only render the children if the user is logged in

import React from 'react';

import './AccessWall.css';

export const AccessWall = ({ children }) => {
  const [userEmail, setUserEmail] = React.useState(localStorage.getItem('userEmail'));
  const [sendingEmail, setSendingEmail] = React.useState(false);

  const style = {
    container: {
        padding: '20px',
    },
    header: {
        textAlign: 'center',
        color: '#F77A55',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '35px 0',
    },
    input: {
        width: '300px',
        padding: '10px',
        marginBottom: '10px',
        color: '#000',
    },
    button: {
        width: '150px',
        padding: '10px',
        color: 'white',
        cursor: 'pointer',
        backgroundColor: '#F77A55',
        border: 'none',
    },
};

  const submitEmail = async (event) => {
    try {
      event.preventDefault();
      setSendingEmail(true);

      const endpoint = 'https://server-pi-rose-19.vercel.app/members/leads';

      const email = event.target[0].value;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(email);

      if (!isValidEmail) {
        alert('Please enter a valid email address');
        return;
      }

      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })

      localStorage.setItem('userEmail', email);
      setUserEmail(email);
      sendingEmail(false);

      if (window?.fbq) {
        window.fbq('track', 'Lead');
      }
    } catch (error) {
      setSendingEmail(false);
    }
  };

  return userEmail ? children : (
    <div className="AccessWall" style={style.container}>
      <h1 style={style.header}>Unveil the Essence of Classic Literature</h1>

      <form style={style.form} onSubmit={submitEmail}>
          <input
              type="email"
              placeholder="Enter your email to access the content"
              style={style.input}
              disabled={sendingEmail}
          />
          <button type="submit" style={style.button} disabled={sendingEmail}>{sendingEmail ? 'Sending...' : 'Submit'}</button>
      </form>

      <h2 style={style.header}>Reignite Your Literary Passion Today!</h2>
      <p>
      Are you an ardent literature lover, seeking to reconnect with your favorite classics? Or perhaps you're in search of your next great read from among the world's most revered books? We have just the right thing for you - a curated collection of insightful summaries highlighting the philosophical teachings from 10 iconic works of literature.
      </p>
      <h2 style={style.header}>Act Fast. Your Limited-Time Opportunity is Here!</h2>
      <p>
      Picture yourself reminiscing the nuanced social discourse from Austen's "Pride and Prejudice," or the deep existential questions posed in Dostoevsky's "Crime and Punishment." Rediscover the chilling commentary on totalitarianism in Orwell's "1984," or the moral dilemmas present in Wilde's "The Picture of Dorian Gray."
      </p>
      <h2 style={style.header}>Act Fast. Your Limited-Time Opportunity is Here!</h2>
      <p>
      For a limited time, we're offering exclusive access to this unique collection. To get started on this remarkable literary refresher, simply provide your email below. As a member, you'll be the first to receive these insightful summaries and engage in enlightening discussions with a community of fellow literature enthusiasts.

      <br/><br/> Don't miss out on this chance to reignite your passion for classic literature. Step into this enlightening adventure that will enhance your understanding and appreciation of these literary masterpieces.
      </p>
    </div>
  );
}
