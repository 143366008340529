import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { AccessWall } from './AccessWall'

import logo from './logo.png'
import aliceImage from './img/alice.png'
import draculaImage from './img/dracula.png'
import bigBrotherImage from './img/big_brother.png'
import crimeAndPunishmentImage from './img/crime_and_punishment.png'
import lesMiserablesImage from './img/les_miserables.png'
import picDorianImage from './img/pic_dorian.png'
import prideAndPrejudiceImage from './img/pride_and_prejudice.png'
import theCountOfMonteCristoImage from './img/the_count_of_monte_cristo.png'
import timeMachineImage from './img/time_machine.png'
import warAndPeaceImage from './img/war_and_peace.png'

import playIcon from './img/play_2.svg'

import stopIcon from './img/stop.svg'
import arrowRightIcon from './img/arrow-right.svg'
import arrowLeftIcon from './img/arrow-left.svg'
import instagramLogo from './img/instagram.svg'
import menuIcon from './img/menu-icon.svg'
import './App.css';

import books from './content/books.json'

const imageCovers = {
  26: aliceImage,
  329: draculaImage,
  154: prideAndPrejudiceImage,
  217: lesMiserablesImage,
  469: timeMachineImage,
  434: theCountOfMonteCristoImage,
  399: picDorianImage,
  189: warAndPeaceImage,
  301: bigBrotherImage,
  336: crimeAndPunishmentImage,
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [currentBook, setCurrentBook] = useState(books[0])
  const [currentChunkPlaying, setCurrentChunkPlaying] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)

  const findBookByChunkId = (chunkId) => {
    return books
      .find(book => book.contentSummary.chunks.map(chunk => String(chunk.id)).includes(String(chunkId)))
  }

  const isCurrentBookPlaying = isPlaying && currentBook.contentSummary.chunks.map(({ id }) => id).includes(currentChunkPlaying.id)
  const isInTheSameBookThatIsInThePlayer = currentBook.id === findBookByChunkId(currentChunkPlaying.id)?.id


  const play = useCallback((chunkId, _book) => {
    const userEmail = localStorage.getItem('userEmail')

    if (!userEmail) {
      alert('Please enter your email to unlock the content')
      return
    }

    const firstChunk = (_book || currentBook)
      .contentSummary
      .chunks
      .sort((a, b) => a.order - b.order)[0]

    const currentChunkId = chunkId || firstChunk.id

    const book = findBookByChunkId(currentChunkId)

    const chunkToPlay = book.contentSummary.chunks.find(({ id }) => id === currentChunkId)

    setCurrentChunkPlaying(chunkToPlay)
    setIsPlaying(true)
  }, [currentBook])

  const pause = () => {
    setIsPlaying(false)
  }

  const playNextChunk = () => {
    const currendBookPlaying = findBookByChunkId(currentChunkPlaying.id)
    const sortedChunks = currendBookPlaying.contentSummary.chunks.sort((a, b) => a.order - b.order)
    const currentChunkIndex = sortedChunks.findIndex(({ id }) => id === currentChunkPlaying.id)
    const nextChunk = sortedChunks[currentChunkIndex + 1]

    if (nextChunk) {
      play(nextChunk.id, currendBookPlaying)
    }
  }

  const playPreviousChunk = () => {
    const currendBookPlaying = findBookByChunkId(currentChunkPlaying.id)
    const sortedChunks = currendBookPlaying.contentSummary.chunks.sort((a, b) => a.order - b.order)
    const currentChunkIndex = sortedChunks.findIndex(({ id }) => id === currentChunkPlaying.id)
    const previousChunk = sortedChunks[currentChunkIndex - 1]

    if (previousChunk) {
      play(previousChunk.id, currendBookPlaying)
    }
  }

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      pause()
    }
    else {
      play(currentChunkPlaying?.id)
    }
  }, [isPlaying, currentChunkPlaying?.id, play, pause])


  const isChunkPlaying = (chunkId) => {
    return isPlaying && currentChunkPlaying.id === chunkId
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        event.preventDefault()
        togglePlay()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [togglePlay])

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
    }
    else {
      audioRef.current.pause()
    }
  }, [isPlaying])

  useEffect(() => {
    if (isPlaying && currentChunkPlaying) {
      audioRef.current.play()
    }
  }, [currentChunkPlaying, isPlaying])

  useEffect(() => {
    // scroll page to the top
    window.scrollTo(0, 0)
  }, [currentBook, currentChunkPlaying])


  return (
    <div className="App">
      <header className="mobile-menu">
        <div className="logo-container">
          <img src={logo} alt="logo" />
          <h1>DeltaClub</h1>
        </div>
        <button
          className="menu-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <img style={{ width: 30, height: 30 }} src={menuIcon} alt="menu" />
        </button>
      </header>

      <nav
        className={['drawer', isMenuOpen ? 'drawer--open' : 'drawer--hidden'].join(' ')}
      >
        <div className="logo-container logo-in-navbar">
          <img src={logo} alt="logo" />
          <h1>DeltaClub</h1>
        </div>

        {books.map((book, index) => (
          <div
            key={index}
            className={[
              'drawer-link',
              currentBook.id === book.id ? 'drawer-link--active' : ''
            ].join(' ')}
            onClick={() => {
              setCurrentBook(book)
              setIsMenuOpen(false)
            }}
          >{book.title} by {book.author}</div>
        ))}
      </nav>

      <main className="main-content">
        <section className="hero">
          <img src={imageCovers[currentBook.id]} alt={currentBook.title} />

          <div className="hero-info">
            <div className="hero-buttons">
              <button className="hero-button" onClick={isInTheSameBookThatIsInThePlayer ? togglePlay : () => play()}>
                <img style={{ width: 40, height: 40 }} src={isCurrentBookPlaying ? stopIcon : playIcon} alt="play control" />
              </button>
            </div>

            <div className="hero-text">
              <h2>{currentBook.title}</h2>
              <h3>by {currentBook.author}</h3>
            </div>
          </div>

        </section>

        <AccessWall>
          <section className="text">
            {currentBook.contentSummary.chunks.sort((a, b) => a.order - b.order).map((chunk, index) => (
              <section key={index} className={[currentChunkPlaying.id === chunk.id ? 'section--active': isInTheSameBookThatIsInThePlayer ? 'section--hide' : ''].join(' ')}>
                {(index === 0 && !isChunkPlaying(chunk.id)) && (
                  <div className="deltaclub-description">
                    <h2>{currentBook.title}</h2>

                    <ReactMarkdown className="text-body">{currentBook.finalDetails}</ReactMarkdown>
                  </div>
                )}
                <h2 className={[isChunkPlaying(chunk.id) ? 'active': ''].join(' ')}>{chunk.title}</h2>
                <ReactMarkdown className="text-body">{chunk.text}</ReactMarkdown>
              </section>
            ))}
          </section>
        </AccessWall>
      </main>

      <section className="sidebar">
        <div className="sidebar-content">
          <div className="sidebar-text">
            <h2>Disclaimer:</h2>
            <h3>DeltaClub is an independent app being created from book lovers to book lovers. <br />Show your support by following us on our social media.<br /> We would love to talk to you there. ❤️</h3>
          </div>
        </div>

        <a href="https://www.instagram.com/deltaclub_app/" target="_blank" rel="noopener noreferrer">
          <div className="sidebar-content">
            <img src={instagramLogo} alt="Instagram Logo" />

            <div className="sidebar-text">
              <h2>@deltaclub_app</h2>
            </div>
          </div>
        </a>

        <div className="sidebar-content">
          <div className="sidebar-text">
            <h2>Disclaimer 2:</h2>
            <h3>This is a web demo. Android and IOS apps with all social features soon. Stay tuned!</h3>
          </div>
        </div>

        <div className="sidebar-content">
          <div className="sidebar-text">
            <h3>By utilizing any DeltaClub services and products, you acknowledge and agree to abide by our <a href='https://www.deltaclub.app/terms-and-conditions/' target='_blank' rel="noreferrer">terms and conditions</a>, <a href='https://www.deltaclub.app/data-policy/' target='_blank' rel="noreferrer">data policy</a>, and <a href='https://www.deltaclub.app/cookies-policy/' target='_blank' rel="noreferrer">cookies policy</a>.</h3>
          </div>
        </div>
      </section>

      {currentChunkPlaying && (
        <div className="player">
          <h2>{findBookByChunkId(currentChunkPlaying.id).title}</h2>
          <h3>{currentChunkPlaying.title}</h3>
          <div style={{ display: 'inline-flex', alignItems: 'center', margin: '10px 0' }}>
            <button className="hero-button" onClick={playPreviousChunk}>
              <img style={{ width: 40, height: 40 }} src={arrowLeftIcon} alt="play previous book summary text" />
            </button>

            <button className="hero-button" onClick={togglePlay}>
              <img style={{ width: 65, height: 65 }} src={isPlaying ? stopIcon : playIcon} alt="play control" />
            </button>

            <button className="hero-button" onClick={playNextChunk}>
              <img style={{ width: 40, height: 40 }} src={arrowRightIcon} alt="play next book sumary text" />
            </button>
          </div>
        </div>
      )}

      <audio
        style={{ display: 'none' }}
        // controls
        ref={audioRef}
        src={currentChunkPlaying?.audio}
        onEnded={() => {
          const currendBookPlaying = findBookByChunkId(currentChunkPlaying.id)
          const sortedChunks = currendBookPlaying.contentSummary.chunks.sort((a, b) => a.order - b.order)
          const currentChunkIndex = sortedChunks.findIndex(({ id }) => id === currentChunkPlaying.id)
          const nextChunk = sortedChunks[currentChunkIndex + 1]

          if (nextChunk) {
            play(nextChunk.id)
          }
          else {
            pause()
          }
        }}
        onPlay={() => {
          setIsPlaying(true)
        }}
        onPause={() => {
          setIsPlaying(false)
        }}
      />
    </div>
  );
}

export default App;
